import {
  QUOTA_RATES_REQUEST,
  QUOTA_RATES_SUCCESS,
  QUOTA_RATES_FAILURE,
} from "./quotaRatesType";
const quotaRateState = {
  success: false,
  loading: false,
  quotaRate: [],
  error: "",
};
const quotaRatesReducer = (state = quotaRateState, action: any) => {
  switch (action.type) {
    case QUOTA_RATES_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case QUOTA_RATES_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        quotaRate: action.payload,
        error: "",
      };
      return setSignUpState;
    case QUOTA_RATES_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        quotaRate: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default quotaRatesReducer;
