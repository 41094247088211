import React from "react";
import SidePanel from "../component/sidePanel/SidePanel";
import Header from "../component/header/Header";
import TermsOfServiceComp from "../layouts/termsOfService/TermsOfServiceComp";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import translation from "../translation/translation";

const TermsOfService = () => {
  const { theme } = useSelector((state: any) => state.getThemeReducer);
  const { lang } = useSelector((state: any) => state.getLanguageReducer);

  return (
    <div className={"authWrapper authWrapperDark"}>
      <Helmet>
        <title>
          {
            translation["helmet"]["login"]["title"][
              lang as keyof typeof translation["helmet"]["login"]["title"]
            ]
          }
        </title>
      </Helmet>
   
      <div >
        
     
          <TermsOfServiceComp />
       
      </div>
    </div>
  );
};

export default TermsOfService;
