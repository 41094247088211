import axios from "axios";
import { SIGNUP_REQUEST, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "./signupType";
const userState = {
  // success: false,
  isLoggedIn: false,
  loading: false,
  user: [],
  error: "",
};
const signUpState = () => {
  const auth: any = localStorage.getItem("investium-auth");
  try {
    const authObj = JSON.parse(auth);
    const { token } = authObj.user;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return authObj;
  } catch (error) {
    return userState;
  }
};
const newSignUpState = signUpState();
const signUpReducer = (state = newSignUpState, action: any) => {
  switch (action.type) {
    case SIGNUP_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case SIGNUP_SUCCESS:
      const setSignUpState = {
        ...state,
        isLoggedIn: true,
        loading: action.loading,
        user: action.payload,
        error: "",
      };
      localStorage.setItem("investium-auth", JSON.stringify(setSignUpState));

      return setSignUpState;
    case SIGNUP_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loading: action.loading,
        user: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default signUpReducer;
