import {
  CREATE_INVESTMENT_DEPOSIT_REQUEST,
  CREATE_INVESTMENT_DEPOSIT_SUCCESS,
  CREATE_INVESTMENT_DEPOSIT_FAILURE,
} from "./createInvestmentDepositType";
const depositState = {
  success: false,
  loading: false,
  deposit: [],
  error: "",
};
const createMyInvestmentDepositReducer = (
  state = depositState,
  action: any
) => {
  switch (action.type) {
    case CREATE_INVESTMENT_DEPOSIT_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case CREATE_INVESTMENT_DEPOSIT_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        deposit: action.payload,
        error: "",
      };
      return setSignUpState;
    case CREATE_INVESTMENT_DEPOSIT_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        deposit: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default createMyInvestmentDepositReducer;
