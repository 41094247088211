import {
  EXCHANGE_REQUEST,
  EXCHANGE_SUCCESS,
  EXCHANGE_FAILURE,
} from "./exchangeType";
const exchangeState = {
  success: false,
  loading: false,
  exchange: [],
  error: "",
};
const exchangeReducer = (state = exchangeState, action: any) => {
  switch (action.type) {
    case EXCHANGE_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case EXCHANGE_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        exchange: action.payload,
        error: "",
      };
      return setSignUpState;
    case EXCHANGE_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        exchange: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default exchangeReducer;
