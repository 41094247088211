import {
  GET_MY_INVESTMENT_REQUEST,
  GET_MY_INVESTMENT_SUCCESS,
  GET_MY_INVESTMENT_FAILURE,
} from "./getMyInvestmentTypes";
const investmentState = {
  success: false,
  loading: false,
  investment: [],
  error: "",
};
const InvestmentReducer = (state = investmentState, action: any) => {
  switch (action.type) {
    case GET_MY_INVESTMENT_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case GET_MY_INVESTMENT_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        investment: action.payload,
        error: "",
      };
      return setSignUpState;
    case GET_MY_INVESTMENT_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        investment: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default InvestmentReducer;
