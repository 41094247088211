import {
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
} from "./updatePasswordType";
const passState = {
  success: false,
  loading: false,
  password: [],
  error: "",
};
const updatePasswordReducer = (state = passState, action: any) => {
  switch (action.type) {
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case UPDATE_PASSWORD_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        password: action.payload,
        error: "",
      };
      return setSignUpState;
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        password: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default updatePasswordReducer;
