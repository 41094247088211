import {
  CREATE_INTENT_REQUEST,
  CREATE_INTENT_SUCCESS,
  CREATE_INTENT_FAILURE,
} from "./createPaymentIntentType";
const intentState = {
  success: false,
  loading: false,
  intent: [],
  error: "",
};
const createPaymentIntentReducer = (state = intentState, action: any) => {
  switch (action.type) {
    case CREATE_INTENT_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case CREATE_INTENT_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        intent: action.payload,
        error: "",
      };
      return setSignUpState;
    case CREATE_INTENT_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        intent: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default createPaymentIntentReducer;
