import {
  CREATE_WITHDRAWAL_REQUEST,
  CREATE_WITHDRAWAL_SUCCESS,
  CREATE_WITHDRAWAL_FAILURE,
} from "./createWithdrawalType";

const withdrawalState = {
  success: false,
  loading: false,
  withdrawal: [],
  error: "",
};
const withdrawalReducer = (state = withdrawalState, action: any) => {
  switch (action.type) {
    case CREATE_WITHDRAWAL_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case CREATE_WITHDRAWAL_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        withdrawal: action.payload,
        error: "",
      };
      return setSignUpState;
    case CREATE_WITHDRAWAL_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        withdrawal: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default withdrawalReducer;
