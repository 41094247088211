import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Loading from "../component/loader/Loader";
import TermsOfService from "../pages/TermsOfService";
const ProtectedRoute = lazy(() => import("./ProtectedRoute"));
const PreventedRoute = lazy(() => import("./PreventedRoute"));
const Home = lazy(() => import("../pages/Home"));
const Wallet = lazy(() => import("../pages/Wallet"));
const Earn = lazy(() => import("../pages/Earn"));
const Exchange = lazy(() => import("../pages/Exchange"));
const Membership = lazy(() => import("../pages/Membership"));
const Affiliate = lazy(() => import("../pages/Affiliate"));
const Profile = lazy(() => import("../pages/Profile"));
const Settings = lazy(() => import("../pages/Settings"));
const Login = lazy(() => import("../pages/Login"));
const Signup = lazy(() => import("../pages/Signup"));
const MobileVerification = lazy(() => import("../pages/MobileVerification"));
const Asics = lazy(() => import("../pages/Asics"));
// const CheckOut = lazy(() => import("../pages/CheckOut"));
const ThankYouAfterPurchase = lazy(
  () => import("../pages/ThankYouAfterPurchase")
);

const Config = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <------Regular Routes------> */}
          {/* <Route path="/" element={<Home />}></Route>s */}
          <Route
            path="/"
            element={<Navigate to={`/login`} replace state={"/"} />}
          ></Route>
          {/* <------Prevented Routes------> */}
          <Route
            path="/login"
            element={<PreventedRoute component={<Login />}></PreventedRoute>}
          ></Route>
          <Route
            path="/register"
            element={<PreventedRoute component={<Signup />}></PreventedRoute>}
          ></Route>
          {/* <Route path="/checkout" element={<CheckOut />}></Route> */}
          <Route
            path="/mobile-verification"
            element={<MobileVerification />}
          ></Route>
          {/* <------Protected Routes------> */}
          <Route
            path="/wallet"
            element={<ProtectedRoute component={<Wallet />}></ProtectedRoute>}
          ></Route>
          <Route
            path="/earn"
            element={<ProtectedRoute component={<Earn />}></ProtectedRoute>}
          ></Route>
          <Route
            path="/exchange"
            element={<ProtectedRoute component={<Exchange />}></ProtectedRoute>}
          ></Route>
          <Route
            path="/mining-zone"
            element={<ProtectedRoute component={<Asics />}></ProtectedRoute>}
          ></Route>
          <Route
            path="/membership"
            element={
              <ProtectedRoute component={<Membership />}></ProtectedRoute>
            }
          ></Route>
          <Route
            path="/affiliate"
            element={
              <ProtectedRoute component={<Affiliate />}></ProtectedRoute>
            }
          ></Route>
          <Route
            path="/profile"
            element={<ProtectedRoute component={<Profile />}></ProtectedRoute>}
          ></Route>

          <Route path="/tos" element={<TermsOfService />}></Route>
          <Route
            path="/settings"
            element={<ProtectedRoute component={<Settings />}></ProtectedRoute>}
          ></Route>
          <Route
            path="/success-purchase-:id"
            element={
              <ProtectedRoute
                component={<ThankYouAfterPurchase />}
              ></ProtectedRoute>
            }
          ></Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default Config;
