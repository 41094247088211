import React from "react";

import { useSelector } from "react-redux";
import styles from "./termsofservice.module.css";
import translation from "../../translation/translation";
import { useNavigate } from "react-router-dom";

const TermsOfServiceComp = () => {
  const { theme } = useSelector((state: any) => state.getThemeReducer);
  const { lang } = useSelector((state: any) => state.getLanguageReducer);
  const history = useNavigate();
  return (
    <div className={styles.tosContainer}>
        <h1>TÉRMINOS Y CONDICIONES.</h1>
        <h4 style={{marginTop:"2rem"}}>CONSIDERACIONES PREVIAS.</h4>
        <p style={{marginTop:"1rem"}}>
        GRUPO RIFT es la marca comercial, con domicilio en Luis Thayer Ojeda N°0180 oficina 1407, Providencia, Santiago de Chile, sociedad dedicada a las inversiones, la cual creó el portal web vest-wallet.com (www.vest-wallet.com). Una vez registrado el usuario en la pagina web, el portal le facilita al usuario una cuenta donde podrá depositar su dinero con fines de que Grupo Rift los destine a una inversión, realizando en este acto un préstamo participativo en dinero, el cuál podrá efectuarse en distintas divisas a elección del usuario. El depósito se realiza directamente a la cuenta corriente prestador de los servicios Grupo Rift. Realizando el depósito, Grupo Rift deberá destinar dichos montos a realizar distintos tipos de inversión, pudiendo el usuario escoger los plazos del préstamo para la inversión, si este tendrá una renta fija (pudiendo recobrar el préstamo con la tasa a una fecha determinada) o variable (que pueda realizar el cobro cuando lo desee). Grupo Rift, utilizará el dinero para realizar inversiones en la compra y venta de acciones tanto nacionales como internacionales e intercambiará divisas en el mercado de valores y forex.
Grupo Rift no se hace responsable de las variaciones que puedan tener las divisas.
        </p>
        <h4>2. OBJETO Y ÁMBITO DE APLICACIÓN</h4>
        <p style={{marginTop:"1rem"}}>
            Ese documento regula los término y condiciones bajo los cuales Ud. Tiene derecho acceder y usar Web vest-wallet.com con el fin de realizar un préstamo con fines de inversión.
            Estos Términos y Condiciones serán aplicados y se entenderán incorporados en cada uno de los contratos que celebre con Grupo Rift y por medio del sitio web www.vest-wallet.com
            Antes de utilizar y/o dar en préstamos a Grupo Rift, el Usuario deberá leer atentamente las correspondientes condiciones particulares que se estipulen a tales efectos. La utilización y/o la contratación de dichos servicios específicos implican la aceptación de las condiciones particulares que los regulen en la versión publicada por Grupo Rift en el momento en que se produzca dicha utilización y/o contratación.
            El uso de este sitio web, la aplicación de estos Términos y Condiciones, los actos que ejecute y los contratos que celebre por medio de este sitio web, se encuentran sujetos y sometidos a las leyes de la República de Chile y en especial a la ley 19.496 de protección de los derechos de los consumidores.
            Grupo Rift por lo tanto, aplicará estrictamente todos los beneficios, garantías y derechos reconocidos en favor de los consumidores en la Ley 19.496. 
        </p>
        <h4>3. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h4>
        <p style={{marginTop:"1rem"}}>
            Grupo Rift como autor de obra colectiva, es titular de los derechos de explotación de propiedad intelectual e industrial de www.vest-wallet.com, y es también titular o tiene la correspondiente licencia sobre los derechos de propiedad intelectual, industrial y de imagen sobre de los contenidos disponibles a través de dicha web.
            En ningún caso se entenderá que el acceso y navegación del Usuario implica una renuncia, 
        </p>



        <h4>4. ACCESO</h4>
        <p style={{marginTop:"1rem"}}>
            El acceso al dominio www.vest-wallet.com por parte de los Usuarios tiene carácter libre y gratuito. No obstante, algunos de los servicios y contenidos ofrecidos por Grupo Rift o terceros a través de www.vest.wallet.com pueden encontrarse sujetos a la contratación previa del servicio o producto y al pago de una cantidad de dinero en la forma que se determine en las correspondientes condiciones particulares, en cuyo caso se pondrían a disposición del Usuario de forma expresa y clara.
            Cuando sea necesario que el Usuario se registre o aporte datos personales para poder acceder a alguno de los servicios específicos, la recogida, el tratamiento y, en su caso, la cesión o el acceso de los datos personales de los Usuarios será de aplicación lo dispuesto en la Política de Privacidad recogida en www.vest-wallet.com.
            Queda prohibida la contratación de productos y servicios a través del dominio www.vest-wallet.com por parte de menores de edad, quienes deberán obtener con anterioridad el debido consentimiento de sus padres, tutores o representantes legales, los cuales serán considerados como responsables de los actos que lleven a cabo los menores a su cargo, conforme a lo estipulado en la normativa vigente.
        </p>

        <h4>5. UTILIZACIÓN DE LA PÁGINA</h4>
        <p style={{marginTop:"1rem"}}>
            Los contenidos incluidos en www.vest-wallet.com se facilitan únicamente a consumidores o usuarios finales. Cualquier uso comercial no autorizado de los mismos, o su reventa, quedan prohibidos, salvo que se cuente con la previa autorización por escrito de Grupo Rift. – Si para la utilización y/o contratación de un servicio en www.vest-wallet.com, el Usuario debiera proceder a su registro, éste será responsable de aportar información veraz y lícita. Si como consecuencia del registro, se dotara al Usuario de una contraseña, éste se compromete a hacer un uso diligente y a mantener en secreto la contraseña para acceder a estos servicios. En consecuencia, los Usuarios son responsables de la adecuada custodia y confidencialidad de cualesquiera identificadores y/o contraseñas que le sean suministradas por Grupo Rift y se comprometen a no ceder su uso a terceros, ya sea temporal o permanente, ni a permitir su acceso a personas ajenas. Será responsabilidad del Usuario la utilización ilícita de los servicios por cualquier tercero ilegítimo que emplee a tal efecto una contraseña a causa de una utilización no diligente o de la pérdida de la misma por el Usuario. En cualquier caso, debe tenerse en cuenta que la identificación y autenticación mediante nombre de usuario y contraseña es un método menos fiable en comparación con la firma electrónica avanzada.
            En virtud de lo anterior, es obligación del Usuario informar de manera inmediata a los gestores de www.vest-wallet.com (enviando un correo electrónico a la dirección info@vest-wallet.com  informando de cualquier hecho que permita el uso indebido de los identificadores y/o contraseñas, tales como el robo, extravío, o el acceso no autorizado a los mismos, con el fin de proceder a su inmediata cancelación. Mientras no se comuniquen tales hechos, Grupo Rift quedará eximido de cualquier responsabilidad que pudiera derivarse del uso indebido de los identificadores o contraseñas por terceros no autorizados.

            El acceso, navegación y uso de www.vest-wallet.com es responsabilidad del Usuario, por lo que el Usuario se compromete a observar diligente y fielmente cualquier instrucción adicional impartida por Grupo Rift relativa al uso de www.vest-wallet.com y de sus contenidos.
            Por tanto, el Usuario se obliga a usar los contenidos de forma diligente, correcta y lícita. En particular se compromete a abstenerse de:
            Utilizar los contenidos con fines o efectos contrarios a la ley, a la moral, a las buenas costumbres generalmente aceptadas o al orden público;
            Reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido;
            Emplear los contenidos y, en particular, la información de cualquier clase obtenida a través de la página o de los servicios para remitir publicidad, comunicaciones con fines de venta directa o con cualquier otra clase de finalidad comercial, mensajes no solicitados dirigidos a una pluralidad de personas con independencia de su finalidad, así como a abstenerse de comercializar o divulgar de cualquier modo dicha información.

        </p>

        <h4>6. LICENCIA SOBRE LAS COMUNICACIONES</h4>
        <p style={{marginTop:"1rem"}}>
            En el caso de que el Usuario envíe información de cualquier tipo a www.vest-wallet.com, él mismo declara, garantiza y acepta:
            i) que tiene derecho a hacerlo libremente,
            ii) que dicha información no infringe ningún derecho de propiedad intelectual, de marca, de patente, secreto comercial, o cualquier otro derecho de tercero,
            iii) que dicha información no tiene carácter confidencial y
            iv) que dicha información no es perjudicial para terceros.
            El Usuario reconoce asumir la responsabilidad y dejará indemne a Grupo Rift por cualquier comunicación que suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna a la exactitud, legalidad, originalidad y titularidad de la misma.
            La aceptación de las presentes condiciones generales implica la cesión en exclusiva por parte del Usuario de cualquier derecho de explotación de propiedad intelectual o industrial que el Usuario pudiera ostentar sobre las comunicaciones que realice a través de www.vest-wallet.com.


        </p>

        <h4>7. RESPONSABILIDADES Y GARANTÍAS</h4>
        <p style={{marginTop:"1rem"}}>
            Grupo Rift no garantiza la licitud, fiabilidad, utilidad, veracidad o exactitud de los servicios o de la información que se presten a través de www.vest-wallet.com
            En consecuencia, Grupo Rift no garantiza ni se hace responsable de: i) la continuidad de los contenidos de www.vest-wallet.com; ii) la ausencia de errores en dichos contenidos ni la corrección de cualquier defecto que pudiera ocurrir; iii) la ausencia de virus y/o demás componentes dañinos en www.vest-wallet.com o en el servidor que lo suministra; iv) la invulnerabilidad de www.vest-wallet.com y/o la inexpugnabilidad de las medidas de seguridad que se adopten en el mismo; v) la falta de utilidad o rendimiento de los contenidos de www.vest-wallet.com; vi) los daños o perjuicios que cause, a sí mismo o a un tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que Grupo Rift establece en www.vest-wallet.com o a través de la vulneración de los sistemas de seguridad de www.vest-wallet.com. No obstante, Grupo Riftt declara que ha adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento de www.vest-wallet.com y evitar la existencia y transmisión de virus y demás componentes dañinos a los Usuarios.



        </p>

        <h4>8. ENLACES</h4>
        <p style={{marginTop:"1rem"}}>
            En www.vest-wallet.com el Usuario podrá encontrar enlaces a otras páginas web mediante diferentes botones, links, banners, etc., los cuales son gestionados por terceros. Grupo Rift no tiene facultad ni medios humanos ni técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web a los que se establecen enlaces desde www.vest-wallet.com. En consecuencia, Grupo Rift no puede asumir ningún tipo de responsabilidad por cualquier aspecto relativo a la página web a la que se establece un enlace desde www.vest-wallet.com, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos en general.
            El establecimiento de cualquier tipo de enlace por parte de www.vest-wallet.com a otro sitio web ajeno no implica que exista algún tipo de relación, colaboración o dependencia entre Grupo Rift y el responsable del sitio web ajeno. Enlaces en otros sitios web con destino a www.vest-wallet.com.
            Si cualquier Usuario, entidad o sitio web deseara establecer algún tipo de enlace con destino a www.vest-wallet.com deberá atenerse a las siguientes estipulaciones:

            El enlace se podrá dirigir a la Página Principal o a cualquier otra de las integradas en www.vest-wallet.com
            El enlace debe ser absoluto y completo, es decir, debe llevar al Usuario, mediante un clic, a la propia dirección URL de vest-wallet.com o a las URLs correspondientes a las páginas que desee enlazar, y debe abarcar completamente toda la extensión de la pantalla de www.vest-wallet.com. En ningún caso, salvo que Grupo Rift lo autorice de manera expresa y por escrito, el sitio web que realiza el enlace podrá reproducir, de cualquier manera, www.vest-wallet.com, incluirlo como parte de su web o dentro de uno de sus “frames” o crear un “browser” sobre cualquiera de las páginas de www.vest-wallet.com.
            En la página que establece el enlace no se podrá declarar de ninguna manera que Grupo Rift a autorizado tal enlace, salvo que lo haya hecho expresamente y por escrito. Si la entidad que realiza el enlace desde su página a Vest wallet correctamente deseara incluir en su página web la marca, denominación, nombre comercial, rótulo, logotipo, slogan o cualquier otro tipo de elemento identificativo de Grupo Rift y/o de www.vest-wallet.com, deberá contar previamente con la correspondiente autorización expresa y por escrito.
            Grupo Rift no autoriza el establecimiento de un enlace a www.vest-wallet.com desde aquellas páginas web que contengan materiales, información o contenidos ilícitos, ilegales, degradantes, obscenos, y en general, que contravengan la moral, el orden público o las normas sociales generalmente aceptadas.
            Grupo Rift no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar toda la información, contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos enlaces con destino www.vest-wallet.com. Grupo Rift no asume ningún tipo de responsabilidad por cualquier aspecto relativo a www.vest-wallet.com que establece ese enlace con destino a www.vest-wallet.com, en concreto, a título enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general. Servicios prestados por terceros a través de www.vest-wallet.com.
            Grupo Rift  no garantiza la licitud, fiabilidad y utilidad de los servicios prestados por terceros a través de www.vest-wallet.com sobre los que Grupo Rift únicamente actúe como medio cauce publicitario.
            Grupo Rift no será responsable de los daños y perjuicios de toda naturaleza causados por los servicios prestados por terceros a través de www.vest-wallet.com, y en particular, a título meramente enunciativo, los causados por:
            El incumplimiento de la ley, la moral, las buenas costumbres o el orden público;
            La incorporación de virus o cualquier otro código informático, archivo o programa que pueda dañar, interrumpir o impedir el normal funcionamiento de cualquier software, hardware o equipo de telecomunicaciones;
            La infracción de los derechos de propiedad intelectual e industrial, de los secretos empresariales, o de compromisos contractuales de cualquier clase.
            La realización de actos que constituyan publicidad ilícita, engañosa o desleal y, en general, que constituyan competencia desleal;
            La falta de veracidad, exactitud, calidad, pertinencia y/o actualidad de los contenidos transmitidos, difundidos, almacenados, recibidos, obtenidos, puestos a disposición o accesibles.
            La infracción de los derechos al honor, a la intimidad personal y familiar y a la imagen de las personas o, en general, cualquier tipo de derechos de terceros;
            La inadecuación para cualquier tipo de propósito y la defraudación de las expectativas generadas, o los vicios y defectos que pudieran generarse en la relación con terceros;
            El incumplimiento, retraso en el cumplimiento, cumplimiento defectuoso o terminación por cualquier causa de las obligaciones contraídas por terceros y contratos realizados con terceros.




        </p>

        <h4>9. DURACIÓN Y MODIFICACIÓN</h4>
        <p style={{marginTop:"1rem"}}>
            Grupo Rift podrá modificar los términos y condiciones aquí estipulados, total o parcialmente, publicando cualquier cambio en la misma forma en que aparecen estas condiciones generales o a través de cualquier tipo de comunicación dirigida a los Usuarios.

            La vigencia temporal de estas condiciones generales coincide, por lo tanto, con el tiempo de su exposición, hasta que sean modificadas total o parcialmente, momento en el cual pasarán a tener vigencia las condiciones generales modificadas.

            Con independencia de lo dispuesto en las condiciones particulares, Grupo Rift  podrá dar por terminado, suspender o interrumpir, en cualquier momento, y sin necesidad de preaviso, el acceso a los contenidos de la página, sin posibilidad por parte del Usuario de exigir indemnización alguna. Tras dicha extinción, seguirán vigentes las prohibiciones de uso de los contenidos expuestas anteriormente en las condiciones generales.
        </p>

        <h4>10. GENERALIDADES</h4>
        <p style={{marginTop:"1rem"}}>
            Los encabezamientos de las distintas cláusulas son sólo informativos, y no afectarán, calificarán o ampliarán la interpretación de las condiciones generales.
            En caso de existir discrepancia entre lo establecido en estas condiciones generales y las condiciones particulares de cada servicio específico, prevalecerá lo dispuesto en éstas últimas.
            En el caso de que cualquier disposición o disposiciones de estas condiciones generales fuera(n) considerada(s) nula(s) o inaplicable(s), en su totalidad o en parte, por cualquier Juzgado, Tribunal u órgano administrativo competente, dicha nulidad o inaplicación no afectará a las otras disposiciones de las condiciones generales ni a las condiciones particulares de los diferentes servicios de Grupo Rift.
            El no ejercicio o ejecución por parte de Grupo Rift a de cualquier derecho o disposición contenida en estas condiciones generales no constituirá una renuncia al mismo, salvo reconocimiento y acuerdo por escrito por su parte.
        </p>

        <h4>11. JURISDICCIÓN</h4>
        <p style={{marginTop:"1rem"}}>
            Las relaciones establecidas entre Grupo Rift y el Usuario se regirán por lo dispuesto en la normativa vigente acerca de la legislación aplicable y la jurisdicción competente. No obstante, para los casos en los que la normativa prevea la posibilidad a las partes de someterse bajo jurisdicción judicial, Grupo Rift y el Usuario, con renuncia expresa a cualquier otro fuero que pudiera corresponderles, se someten a los Juzgados y Tribunales ordinarios de Santiago de Chile.
        </p>

        <h1 style={{marginTop:"4rem"}}>POLITICA DE PRIVACIDAD</h1>
        <p style={{marginTop:"2rem"}}>
            En virtud de lo dispuesto en la vigente normativa en materia de Protección de Datos de Carácter Personal, Grupo Rift pone en su conocimiento que:

        </p>
        <p>
            PRIMERO. – La visita del sitio web de Grupo Rift en su dominio www.vest-wallet.com no implica que el usuario deba suministrar información alguna respecto a su identidad, no pudiendo ser asociada a un usuario concreto por la empresa. En el supuesto que se proporcionen datos de carácter de personal, estos datos serán recogidos y tratados respetando los derechos de los afectados y conforme a las limitaciones recogidas en la vigente normativa en materia de protección de datos. Los usuarios que faciliten datos de carácter personal aceptan de forma clara, exacta e inequívoca las presentes condiciones.

        </p>
        <p>
            SEGUNDO. – Los datos personales proporcionados por el usuario a través del presente sitio web, se incorporarán a los ficheros de titularidad privada de Grupo Rift, cuya finalidad será exclusivamente la gestión de la relación comercial de los clientes con la compañía y cumplimiento de la prestación de los servicios ofrecidos, y el envío de comunicaciones a diferentes personas de contacto. Los referidos datos personales proporcionados por el usuario serán comunicados, en caso de ser necesario, a administraciones públicas y a todas aquellas entidades con las que sea necesaria la comunicación con la finalidad de cumplir con la prestación de los servicios contratados, incluyendo transferencias de datos a entidades internacionales si fuera el caso. El hecho de no facilitar los datos a las entidades mencionadas podría implicar que no se pueda cumplir con la prestación de los servicios objeto del presente contrato.

        </p>
        <p>
            TERCERO. – Grupo Rift ha desarrollado todos los sistemas e implementado las medidas técnicas y organizativas a su alcance, previstas en la normativa de protección de datos de carácter personal, para evitar la pérdida, mal uso, alteración, acceso no autorizado y sustracción de los datos de carácter personal facilitados por el usuario.

        </p>

        <p>

            CUARTO. – El usuario garantiza que los datos que, en su caso, haya aportado a través del presente sitio web son verdaderos, exactos, completos y actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse
            Como consecuencia del incumplimiento de tal obligación. Por su parte, Grupo Rift procederá a tratar los datos de manera lícita, leal, transparente, adecuada, pertinente, limitada, exacta y actualizada. Es por ello por lo que Grupo Rift se compromete a adoptar todas las medidas razonables para que estos se supriman o rectifiquen sin dilación cuando sean inexactos.
        </p>
        <p>

            QUINTO. – Grupo Rift se compromete al cumplimiento de su obligación de secreto con respecto a los datos de carácter personal recibidos a través del presente sitio web y al deber de tratarlos con confidencialidad. Los datos personales recabados no serán cedidos a terceros (salvo que medie obligación legal) sin el consentimiento expreso del titular de los mismos.
        </p>
        <p>

            SEXTO. – De acuerdo con los derechos que le confiere la normativa vigente en protección de datos podrá ejercer los derechos de acceso, rectificación, limitación de tratamiento, supresión, portabilidad y oposición al tratamiento de sus datos de carácter personal, así como del consentimiento prestado para el tratamiento de los mismos, dirigiendo su petición a la dirección postal indicada más arriba o al correo electrónico info@vest-wallet.com. La retirada del consentimiento al tratamiento de datos conllevaría la resolución de los servicios contratados, ya que para poder prestar nuestros servicios es imprescindible poder tratarlos. 
        </p>
        <p>

            SÉPTIMO. – Grupo Rift, una vez finalizada la relación con el usuario, conservará los datos durante el tiempo imprescindible para cumplir las obligaciones legales vigentes. A su vez, procederá a la cancelación de los datos recogidos cuando dejen de ser necesarios a la finalidad para la que fueron recabados.
        </p>

        <h1 style={{marginTop:"4rem"}}>POLITICA DE COOKIES</h1>
        <p style={{marginTop:"1em"}}>
            Utilizamos cookies para facilitar el uso de nuestra página web. Las cookies son pequeños ficheros de información cuya función principal es almacenar y recuperar información sobre los hábitos de navegación de un usuario o su equipo. También pueden llegar a permitirnos reconocerle como usuario y así mejorar consecuentemente el proceso de navegación. Las cookies que utilizamos no almacenan dato personal alguno, ni ningún tipo de información que pueda identificarle. Para continuar sin cambios en la configuración de las cookies, simplemente continúe en la página web. Puede controlar el uso de cookies aceptando o rechazando su instalación mediante la configuración del navegador. En caso de no hacerlo, entendemos que está aceptando el uso de las cookies. Puede obtener más información sobre las cookies y su uso en aboutcookies.org. Los tipos de cookies que utilizamos son los siguientes:
            Propias: aquellas que se envían al terminal del usuario desde un equipo gestionado por el dominio al que el usuario accede y del que solicita el servicio.
            De terceros: aquellas que se envían al terminal de usuario desde un equipo gestionado por un tercero, distinto al que trata los datos obtenidos a través de la cookie.
            De sesión: aquellas cuya temporalidad está vinculada al tiempo que el usuario accede a la web.
            Persistentes: aquellas que se almacenan en el terminal durante un determinado tiempo, definido por el responsable de la cookie.
            Técnicas: aquellas que permiten la navegación a través de la web y la utilización de las diferentes opciones o servicios.
            Personalización: aquellas que permiten al usuario predefinir ciertas características generales de su terminal.
            Análisis: aquellas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios en las webs a las que están vinculadas.
            La presente política de cookies se aplica a todas las páginas web de Grupo Rift. Con su utilización se pretende adquirir un mayor conocimiento sobre la actividad de los usuarios, con ánimo de mejorar y facilitar nuestro servicio online.
        </p>

        <button className={styles.btnBack} type="submit" onClick={()=> history("/register")}>
          
            
        {
            translation["tos"]["go_back"][
              lang as keyof typeof translation["tos"]["go_back"]
            ]
          }
        </button>
    </div>
  );
};

export default TermsOfServiceComp;
