import {
  CREATE_DEPOSIT_REQUEST,
  CREATE_DEPOSIT_SUCCESS,
  CREATE_DEPOSIT_FAILURE,
} from "./createDepositType";
const depositState = {
  success: false,
  loading: false,
  deposit: [],
  error: "",
};
const depositReducer = (state = depositState, action: any) => {
  switch (action.type) {
    case CREATE_DEPOSIT_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case CREATE_DEPOSIT_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        deposit: action.payload,
        error: "",
      };
      return setSignUpState;
    case CREATE_DEPOSIT_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        deposit: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default depositReducer;
