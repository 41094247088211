import {
  STATICS_REQUEST,
  STATICS_SUCCESS,
  STATICS_FAILURE,
} from "./staticsType";
const staticsState = {
  success: false,
  loading: false,
  statics: [],
  error: "",
};
const getStaticsReducer = (state = staticsState, action: any) => {
  switch (action.type) {
    case STATICS_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case STATICS_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        statics: action.payload,
        error: "",
      };
      return setSignUpState;
    case STATICS_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        statics: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default getStaticsReducer;
