import axios from "axios";
import { SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE } from "./signInType";
const userState = {
  isLoggedIn: false,
  loading: false,
  user: [],
  error: "",
};
//helper function to create localStorage
const getAuthState = () => {
  const auth: any = localStorage.getItem("investium-auth");
  try {
    const authObj = JSON.parse(auth);
    const { token } = authObj.user;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    return authObj;
  } catch (error) {
    return userState;
  }
};
const newAuth = getAuthState();
const signInReducer = (state = newAuth, action: any) => {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        loading: action.loading,
      };
    case SIGNIN_SUCCESS:
      const newAuthState = {
        ...state,
        isLoggedIn: true,
        loading: action.loading,
        user: action.payload,
        error: "",
      };
      localStorage.setItem("investium-auth", JSON.stringify(newAuthState));
      return newAuthState;
    case SIGNIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        loading: action.loading,
        user: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default signInReducer;
