import {
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
} from "./userInfoType";
import axios from "axios";
const userState = {
  success: false,
  loading: false,
  user: [],
  error: "",
};
const getUserInfoState = () => {
  const auth = localStorage.getItem("investium-userInfo");
  try {
    if (auth === null) {
      return userState;
    } else {
      const authObj = JSON.parse(auth);

      return authObj;
    }
  } catch (error) {
    return userState;
  }
};
const newUserState = getUserInfoState();
const userInfoReducer = (state = newUserState, action: any) => {
  switch (action.type) {
    case USER_INFO_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case USER_INFO_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        user: action.payload,
        error: "",
      };
      localStorage.setItem(
        "investium-userInfo",
        JSON.stringify(setSignUpState)
      );
      return setSignUpState;
    case USER_INFO_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        user: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default userInfoReducer;
