const dictionary = {
  helmet: {
    login: {
      title: {
        en: "Investium Capital - Login",
        es: "Investium Capital - Inicio",
      },
    },
    signup: {
      title: {
        en: "Investium Capital - SignUp",
        es: "Investium Capital - Registro",
      },
    },
    wallet: {
      title: { en: "Investium Capital ", es: "Investium Capital" },
    },
    earn: {
      title: {
        en: "Investium Capital - Earn",
        es: "Investium Capital - Ganar",
      },
    },
    exchange: {
      title: {
        en: "Investium Capital - Exchange",
        es: "Investium Capital - Intercambio",
      },
    },
    asics: {
      title: {
        en: "Investium Capital - Mining Zone",
        es: "Investium Capital - Mining Zone",
      },
    },
    membership: {
      title: {
        en: "Investium Capital - Membership",
        es: "Investium Capital - Membresía",
      },
    },
    affiliate: {
      title: {
        en: "Investium Capital - Affiliate",
        es: "Investium Capital - Afiliado",
      },
    },
    profile: {
      title: {
        en: "Investium Capital - Profile",
        es: "Investium Capital - Perfil",
      },
    },
    terms_of_service: {
      title: {
        en: "Investium Capital - ToS",
        es: "Investium Capital - Terminos del Servicio",
      },
    },
  },
  tos: {
    go_back: { en: "Go back", es: "Volver" },
  },
  loading: { en: "Loading...", es: "Cargando..." },
  header: {
    dropdown: {
      label_one: { en: "Profile", es: "Perfil" },
      label_two: { en: "Sign out", es: "Cerrar sesión" },
    },
  },
  sidebar: {
    items: [
      {
        name: { en: "Wallet", es: "Billetera" },
      },
      {
        name: { en: "Earn", es: "Ganar" },
      },
      {
        name: { en: "Mining Zone", es: "Mining Zone" },
      },
      {
        name: { en: "Exchange", es: "Intercambio" },
      },
      // {
      //   name: { en: "Membership", es: "Membresía" },
      // },
      {
        // name: { en: "Affiliate", es: "Afiliado" },
      },
      {
        name: { en: "Chat with us!", es: "Chat with us!" },
      },
    ],
  },
  side_panel_for_auth: {
    login: {
      forgot_password: {
        en: "Forgot password?",
        es: "Contraseña olvidada?",
      },
      first_Heading: {
        en: "Access your account",
        es: "Acceda a su cuenta",
      },
      first_para: {
        en: "Log in to your account by entering the email and password.",
        es: "Inicie sesión en su cuenta ingresando el correo electrónico y la contraseña.",
      },
      sec_Heading: { en: "New here?", es: "¿Nuevo aquí?" },
      sec_para: {
        en: "If you are new here try to create an account first. Click below to create an account.",
        es: "Si eres nuevo aquí debes crear una cuenta primero. Haga click a continuación para crear una cuenta.",
      },
      auth_btn: { en: "REGISTER", es: "REGISTRO" },
    },
    sign_up: {
      first_Heading: {
        en: "Enter your personal information",
        es: "Ingrese su información personal",
      },
      first_para: {
        en: "We need you to enter your personal information. Your personal information will be safe.",
        es: "Necesitamos que ingreses tu información personal. Su información personal estará segura.",
      },
      sec_Heading: {
        en: "Do you already have an account?",
        es: "¿Ya tienes una cuenta?",
      },
      sec_para: {
        en: "If you already have an account. You need to login, instead of creating a new one.",
        es: "Si ya tienes una cuenta. Necesitas iniciar sesión, en lugar de crear una nueva.",
      },
      auth_btn: { en: "LOGIN", es: "ACCESO" },
    },
  },
  // pages
  login: {
    title: { en: "Welcome!", es: "¡Bienvenido!" },
    first_input: { en: "Email", es: "Correo electrónico" },
    second_input: { en: "Password", es: "Contraseña" },
    submit_button: { en: "LOGIN", es: "ACCESO" },
    label: { en: "Remember me", es: "Recuérdame" },
  },
  signup: {
    title: { en: "Create your account", es: "Crea tu cuenta" },
    first_input: { en: "First Name", es: "Primer nombre" },
    second_input: { en: "Surname", es: "Apellido" },
    third_input: { en: "Select a Country", es: "Seleccione un país" },
    fourth_input: { en: "Phone Number", es: "Teléfono" },
    fifth_input: { en: "ID Card Number", es: "CI/DNI" },
    sixth_input: { en: "Email", es: "Correo electrónico" },
    seventh_input: { en: "Password", es: "Contraseña" },
    eight_input: { en: "Confirm Password", es: "Confirmar contraseña" },
    terms_of_service_text: {
      en: "By creating an account you accept the",
      es: "Al crear una cuenta aceptas los",
    },
    terms_of_service_word: {
      en: "Terms of Service",
      es: "Terminos del Servicio",
    },
    separator: { en: "Credentials", es: "Credenciales" },
    submit_button: { en: "Create an account", es: "Crear una cuenta" },
    referralCode: { en: "Referral Code", es: "Crear una cuenta" },
  },
  wallet: {
    are_you_sure: {
      en: "Are you sure you want to proceed?",
      es: "Estas seguro que deseas continuar?",
    },
    balance: {
      title: { en: "Balance", es: "Balance" },
      first_button: { en: "Withdraw", es: "Retirar" },
      second_button: { en: "Deposit", es: "Depósito" },
      withdraw_modal_one: {
        title: { en: "Withdraw Funds", es: "Retirar Fondos" },
        description: {
          en: "Withdraw funds from your balance with one of the following options",
          es: "Retira dinero de tu billetera con una de las siguientes opciones",
        },
      },
      withdraw_modal_two_for_bank_types: {
        title: { en: "Withdraw Funds", es: "Retirar Fondos" },
        description: {
          en: "Withdraw your funds to any of our available bank options",
          es: "Retire sus fondos con cualquier opción bancaria disponible",
        },
      },
      withdraw_and_deposit_modal_three: {
        i_have_paid: { en: "I have paid", es: "He hecho el pago" },
        bank_data_text: { en: "Bank Data", es: "Datos bancarios" },
        type_personal_id: {
          en: "Type your personal ID",
          es: "Ingresa tu CI/DNI",
        },
        submit_text: { en: "Submit Withdrawal", es: "Enviar Retiro" },
        success_message: {
          en: "Your withdrawal request has been submitted, funds should arrive in your bank account within 3 business days.",
          es: "Su solicitud de retiro ha sido enviada correctamente, los fondos deberían llegar a su cuenta bancaria dentro de los próximos 3 días hábiles.",
        },
        deposit_bank_disclaimer: {
          en: "The bank account you transfer from, must be registered under the same ID in your Investium Capital",
          es: "La cuenta de la cuál depositas tiene que tener el mismo DNI que tienes registrado en Investium Capital",
        },
        withdraw_title: { en: "Withdraw Funds", es: "Retirar Fondos" },
        deposit_title: { en: "Deposit Funds", es: "Agregar dinero" },
        first_input: { en: "Enter amount", es: "Ingrese la cantidad" },
        second_input: { en: "Select a currency", es: "Seleccione una moneda" },
        // third_input: { en: "Select a currency", es: "Seleccione una moneda" },
        fourth_input: {
          en: "Select a bank account type",
          es: "Seleccione un tipo de cuenta bancaria",
        },
        fifth_input: {
          en: "Enter accont number",
          es: "Ingrese numero de cuenta",
        },
        sixth_input: { en: "Select your bank", es: "Selecciona tu banco" },
        details_to_fill: { en: "User details to fill", es: "Datos de usuario" },
        third_input_for_banks_withdraw: {
          en: "Bank Account Number",
          es: "Número de cuenta bancaria",
        },
        third_input_for_crypto_withdraw: {
          en: "Cryptocurrency Wallet",
          es: "Billetera de criptomonedas",
        },
        third_input_for_skrill_withdraw: {
          en: "Skrill Email",
          es: "Correo electrónico de Skrill",
        },
        third_input_for_banks_deposit: {
          en: "Enter Account Number",
          es: "Ingrese el número de cuenta",
        },
        third_input_for_crypto_deposit: {
          en: "Wallet you transfer from",
          es: "Direccion desde la que transfieres",
        },
        third_input_for_skrill_deposit: {
          en: "From Email",
          es: "Desde el e-mail",
        },
        currency_unavailable: {
          en: "You don't have specified currency available on your account for withdrawal",
          es: "No tiene la moneda especificada disponible en su cuenta para retirar",
        },
        subtitle_transfer: {
          en: "Send money to one of our deposit accounts",
          es: "Envia dinero a una de nuestras cuentas de deposito",
        },

        second_description_for_deposit: {
          en: "Only make the deposit request after you have made the transfer.",
          es: "Solo haz la solicitud de depósito después de haber realizado la transferencia.",
        },
        third_description_for_deposit: {
          en: "Only transfer funds to this address using the Binance BEP20 network. Sending anything else will result in a permanent loss of funds.",
          es: "Solo transfiere fondos a esta direccion usando la red Binance BEP20. Mandando cualquier otra cosa resultara en una perdida permanente de fondos.",
        },
        third_description_for_withdrawal: {
          en: "Only enter a Binance BEP20 network address for withdrawal, choosing anything else will result in a permanent loss of funds.",
          es: "Solo ingresa una direccion en la red Binance BEP20. Ingresando cualquier otra cosa resultara en una perdida permanente de fondos.",
        },
        submit_button_deposit: {
          en: "I've made the transfer",
          es: "He transferido",
        },
        submit_button_withdraw: { en: "Withdraw", es: "Retirar" },
      },
      deposit_bank_modal: {
        bank_text: { en: "Bank Name", es: "Banco" },
        account_type: { en: "Account type", es: "Tipo de cuenta" },
        rut: { en: "RUT", es: "RUT" },
        account_number: { en: "Account number", es: "Numero de cuenta" },
        email: { en: "Email", es: "Mail" },
      },
      deposit_modal_one: {
        title: { en: "Deposit Funds", es: "Fondos de depósito" },
        description: {
          en: "Top up your balance using the following options",
          es: "Recarga tu saldo usando las siguientes opciones",
        },
      },
      deposit_modal_two_for_bank_types: {
        title: { en: "Deposit Funds", es: "Fondos de depósito" },
        description: {
          en: "Deposit your amount in Any of your Bank Account",
          es: "Deposite su cantidad en cualquiera de sus cuentas bancarias",
        },
      },
      stripe_modal_two: {
        title: {
          en: "Create Deposit",
          es: "Crear Depósito",
        },
        label_one: { en: "Currency", es: "Divisa" },
        input_one: { en: "Select a Currency", es: "Seleccione una moneda" },
        label_two: {
          en: "Amount in Original Currency",
          es: "Importe en moneda original",
        },
        input_two: {
          en: "Amount in Original Currency",
          es: "Importe en moneda original",
        },
        submit_button: { en: "Create Deposit", es: "Crear Deposito" },
      },
      stripe_success_modal: {
        title: { en: "Deposit Successful", es: "Depósito exitoso" },
        description: {
          en: "You have successfully deposited in your wallet via",
          es: "Ha depositado con éxito en su billetera a través de",
        },
        submit_button: {
          en: "All Done",
          es: "Todo listo",
        },
      },
      fintoc_modal_two: {
        title: {
          en: "Create Deposit",
          es: "Crear depósito",
        },
        label_one: { en: "Currency", es: "Divisa" },
        input_one: { en: "Select a Currency", es: "Seleccione una moneda" },
        label_two: {
          en: "Amount in Original Currency",
          es: "Importe en moneda original",
        },
        input_two: {
          en: "Amount in Original Currency",
          es: "Importe en moneda original",
        },
        submit_button: { en: "Create Deposit", es: "Crear Depósito" },
      },
    },
    my_assets: {
      title: { en: "My Assets", es: "Mis activos" },
      no_assets_description: {
        en: "You don't have any assets yet",
        es: "Todavía no tienes ningún activo",
      },
    },
    transaction: {
      title: { en: "Transactions", es: "Transacciones" },
      no_transaction_description: {
        en: "No transactions are made yet",
        es: "Aún no se realizan transacciones",
      },
      see_button: { en: "See all", es: "Ver todo" },
      filtered_header: {
        en: "Filtered Transaction",
        es: "Transacción Filtrada",
      },
      no_result_desc: {
        en: "No results Found",
        es: "No se han encontrado resultados",
      },
    },
  },
  membership: {
    title_one: { en: "Active Plan", es: "Plan Activo" },
    active_plan_description: {
      en: "Auto-Renews on every",
      es: "Renovación automática en cada",
    },
    active_plan_description_for_platinum: {
      en: "Never expires",
      es: "Nunca expira",
    },
    manage_subscription: {
      en: " Manage subscription",
      es: "Administrar suscripción",
    },
    title_two: { en: "Membership plans", es: "Planes de Membresía" },
    standard_plan: {
      title: { en: "Standard", es: "Estándar" },
      header: { en: "What's in there", es: "Especificaciones del Plan" },
      standard_plan_desc: [
        { id: 0, data: { en: "Upto 9% APR", es: "Hasta 9% Rentabilidad" } },
        {
          id: 1,
          data: {
            en: "3.99% Conversion Fee",
            es: "Comisión de conversión del 3,99%",
          },
        },
      ],
      active_button: { en: "Active", es: "Activo" },
      submit_button: { en: "Standard", es: "Estándar" },
    },
    silver_plan: {
      title: { en: "Silver", es: "Plata" },
      header: { en: "The perks:", es: "Especificaciones del Plan" },
      silver_plan_desc: [
        { id: 0, data: { en: "Upto 14% APR", es: "Hasta 14% Rentabilidad" } },
        {
          id: 1,
          data: {
            en: "2.99% Conversion Fee",
            es: "Comisión de conversión del 2,99%",
          },
        },
      ],
      active_button: { en: "Active", es: "Activo" },
      submit_button: { en: "$3,99/Month", es: "$3,99/Mes" },
    },
    gold_plan: {
      title: { en: "Gold", es: "Oro" },
      header: { en: "The perks:", es: "Especificaciones del Plan" },
      gold_plan_desc: [
        { id: 0, data: { en: "Upto 19% APR", es: "Hasta 19% Rentabilidad" } },
        {
          id: 1,
          data: {
            en: "1.99% Conversion Fee",
            es: "Comisión de conversión del 1,99%",
          },
        },
        {
          id: 2,
          data: {
            en: "Basic annual return promotions",
            es: "Ofertas de rentabilidad anual *Basic*",
          },
        },
      ],
      active_button: { en: "Active", es: "Activo" },
      submit_button: { en: "$6,99/Month", es: "$6,99/Mes" },
    },
    platinum_plan: {
      title: { en: "Platinum", es: "Platino" },
      header: { en: "The perks:", es: "Especificaciones del Plan" },
      platinum_plan_desc: [
        { id: 0, data: { en: "Upto 25% APR", es: "Hasta 25% Rentabilidad" } },
        {
          id: 1,
          data: {
            en: "0.99% Conversion Fee",
            es: "Comisión de conversión del 0,99%",
          },
        },
        {
          id: 2,
          data: {
            en: "Premium account manager",
            es: "Ejecutivo de cuenta premium",
          },
        },
        {
          id: 3,
          data: {
            en: "Premium annual return promotions",
            es: "Ofertas de rentabilidad anual *Premium*",
          },
        },
      ],
      active_button: { en: "Active", es: "Activo" },
      submit_button: { en: "$9,99/Month", es: "$9,99/Mes" },
    },
    modal: {
      title: {
        en: "Select suitable payment Method",
        es: "Seleccione el método de pago adecuado",
      },
      first_desc: {
        en: "Payments Methods Available",
        es: "Métodos de pago disponibles",
      },
      stripe_desc: {
        en: "Buy membership with credit/debit card",
        es: "Comprar membresía con tarjeta de crédito/débito",
      },
      fintoc_desc: {
        en: "Buy membership through Fintoc",
        es: "Comprar membresía a través de Fintoc",
      },
    },
  },
  affiliate: {
    title: {
      en: "Invite Your Friends and Earn Rewards",
      es: "Invita a Tus Amigos y Gana Recompensas",
    },
    header_one: {
      en: "Invite your friends and earn 5% of what they profit using Investium Capital",
      es: "Inivita a tus amigos y gana un 10% de lo que ellos obtengan utilizando Investium Capital",
    },
    desc: {
      en: "Note: Terms & Conditions will be applied",
      es: "Nota: Se aplicarán términos y condiciones",
    },
    copy_button: { en: "Copy Link", es: "Copiar link" },
    header_two: { en: "My Stats", es: "Progreso" },
    label_one: { en: "Currencies", es: "Monedas" },
    label_two: { en: "Bonuses Collected", es: "Registros" },
    label_three: { en: "Coins Gained", es: "Ganancia" },
  },
  exchange: {
    title: { en: "Exchange", es: "Intercambio" },
    header_one: { en: "Select Currency", es: "Seleccione Divisa" },
    header_two: {
      en: "Exchanged Currency",
      es: "Moneda Intercambiada",
    },
    input: { en: "Choose currency", es: "Elegir Moneda" },
    button: { en: "Exchange Currency", es: "Intercambiar Divisa" },
    success_modal: {
      header: { en: "Exchange Successful", es: "Intercambio exitoso" },
      description: {
        en: "You have successfully exchanged currency in your wallet",
        es: "Haz intercambiado divisas exitosamente en tu billetera",
      },
      button: { en: "All Done", es: "Todo listo" },
    },
  },
  earn: {
    apply: { en: "Apply", es: "Aplicar" },
    claim: { en: "Claim", es: "Reclamar" },

    apply_coupon: { en: "Apply coupon code here", es: "Aplica el cupon aqui" },
    title_one: { en: "Earn", es: "Ganar" },
    earn_table_label_one: { en: "Currency", es: "Divisa" },
    earn_table_label_two: { en: "APR", es: "Rentabilidad" },
    earn_table_label_three: { en: "Duration", es: "Duración" },
    earn_table_button: { en: "Choose", es: "Elegir" },
    earn_table_modal_one: {
      title: {
        en: "Create Plan",
        es: "Crear plan",
      },
      desc: {
        en: "Amount in Original Currency",
        es: "Importe en Moneda Original",
      },
      input: {
        en: "Amount in Original Currency",
        es: "Importe en Moneda Original",
      },
      button: { en: "Create Plan", es: "Crear Plan" },
    },
    earn_table_success_modal: {
      title: { en: "Plan Created", es: "Plan creado" },
      button: { en: "All Done", es: "Todo listo" },
    },
    title_two: { en: "Claim Rewards", es: "Reclamar recompensas" },
    claim_table_label_one: { en: "Currency", es: "Divisa" },
    claim_table_label_two: { en: "Deposited", es: "Depositado" },
    claim_table_label_three: { en: "Est. Annual Return", es: "Rentabilidad Anual" },
    claim_table_label_four: { en: "Profit", es: "Ganancia" },
    claim_button: { en: "Claim your reward", es: "Reclama tu recompensa" },
    final_claim_button: { en: "Final Claim", es: "Reclamo Final" },
    title_three: { en: "Active Plans", es: "Planes Activos" },
    deposit_table_label_one: { en: "Currency", es: "Divisa" },
    deposit_table_label_two: { en: "Deposited", es: "Depositado" },
    deposit_table_label_three: { en: "Est. Annual Return", es: "Rentabilidad Anual" },
    deposit_table_label_four: { en: "Reclaim in", es: "Reclamar en" },
    hour_label: { en: "hours", es: "horas" },
    days_left_label: { en: "Days Left", es: "Días Restantes" },
    claim_success_modal: {
      title: { en: "Reward successfully claimed", es: "Reclamo exitoso" },
      desc: {
        en: "Reward successfully claimed, funds have been added to your Wallet",
        es: "Reclamo exitoso, los fondos han sido agregados a tu Billetera",
      },
      button: { en: "All Done", es: "Todo listo" },
      claimed_coins: {
        en: "",
        es: "",
      },
    },
    total_yield_so_far: {
      en: "Total Yield So Far",
      es: "Rendimiento Total Hasta Ahora",
    },
  },
  profile: {
    joined: { en: "Joined:", es: "Ingresaste:" },

    verf_label: { en: "Verified", es: "Verificado" },
    sub_header: { en: "Subscription", es: "Suscripción" },
    sub_desc: {
      en: "Auto-Renews on every",
      es: " Renovación automática - ",
    },
    sub_desc_for_basic: {
      en: "Never expires",
      es: "Nunca expira",
    },
    manage_subscription: {
      en: " Manage subscription",
      es: "Administrar suscripción",
    },
    upgrade_button: { en: "Upgrade", es: "Mejora" },
    verification_status: {
      en: "Verification Status",
      es: "Estado Verificacion",
    },
    start_verification: {
      en: "Start Verification",
      es: "Empezar Verificacion",
    },
    user_info: {
      title: { en: "Personal Information", es: "Información personal" },
      label_one: { en: "First Name", es: "Nombre" },
      label_two: { en: "Last Name", es: "Apellido" },
      label_three: { en: "ID Card Number", es: "CI/DNI" },
      label_four: { en: "Phone Number", es: "Número de teléfono" },
      label_five: { en: "Country", es: "País" },
      profile_success: {
        header: { en: "Updated", es: "Actualizada" },
        desc: {
          en: "Profile updated successfully",
          es: "Perfil actualizado con éxito",
        },
      },
    },
    update_pass: {
      title: { en: "Update Password", es: "Actualizar contraseña" },
      label_one: { en: "Current Password", es: "Contraseña Actual" },
      label_two: { en: "New Password", es: "Nueva Contraseña" },
      label_three: { en: "Confirm Password", es: "Confirmar contraseña" },
      button: { en: "Update Password", es: "Actualizar contraseña" },
    },
  },
  asics: {
    marketplace: {
      en: "Marketplace",
      es: "Marketplace",
    },
    your_assets: {
      en: "Your Assets",
      es: "Tus Activos",
    },
    buy_shares: {
      en: "Buy Shares",
      es: "Comprar Acciones",
    },
    buy_now: {
      en: "Buy Now",
      es: "Comprar Ahora",
    },
    price: {
      en: "Total Asic Value",
      es: "Precio",
    },
    disclaimer: {
      en: '*Price is estimated, APR changes constantly with BTC price, after purchase you will find it in "Your Assets" tab.',
      es: 'El precio es estimado, el APR cambia constantemente con el precio del BTC, después de la compra lo encontrarás en la pestaña "Tus Activos".',
    },
    model: {
      en: "Model",
      es: "Modelo",
    },
    available_shares: {
      en: "Available Shares",
      es: "Porcentaje Adquirido",
    },
    percentage_owned: {
      en: "Shares Owned",
      es: "Porcentaje Adquirido",
    },
    live_camera: {
      en: "Live Camera",
      es: "Cámara en Vivo",
    },
    buy_shares_success: {
      en: "Shares bought successfully",
      es: "Acciones compradas con éxito",
    },
    sold_out: {
      en: "Sold Out",
      es: "Agotado",
    },
  },
};
export default dictionary;
