import {
  GET_MY_TRANSACTION_REQUEST,
  GET_MY_TRANSACTION_SUCCESS,
  GET_MY_TRANSACTION_FAILURE,
} from "./getMyTransactionsType";
const transactionState = {
  success: false,
  loading: false,
  transaction: [],
  error: "",
};
const transactionReducer = (state = transactionState, action: any) => {
  switch (action.type) {
    case GET_MY_TRANSACTION_REQUEST:
      return {
        ...state,
        success: false,
        loading: action.loading,
      };
    case GET_MY_TRANSACTION_SUCCESS:
      const setSignUpState = {
        ...state,
        success: true,
        loading: action.loading,
        transaction: action.payload,
        error: "",
      };
      return setSignUpState;
    case GET_MY_TRANSACTION_FAILURE:
      return {
        ...state,
        success: false,
        loading: action.loading,
        transaction: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
export default transactionReducer;
