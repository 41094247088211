import { combineReducers } from "redux";
import getThemeReducer from "./getTheme/getThemeReducer";
import getLanguageReducer from "./getLanguage/getLanguageReducer";
import signUpReducer from "./signUp/signUpReducer";
import signInReducer from "./signIn/signInReducer";
import userInfoReducer from "./userInfo/userInfoReducer";
import getCurrentAprRatesReducer from "./getCurrentAprRates/getCurrentAprRatesReducer";
import createWithdrawalReducer from "./createWithdrawal/createWithdrawalReducer";
import getMyTransactionReducer from "./getMyTransactions/getMyTransactionsReducer";
import createInvestmentDepositReducer from "./createInvestmentDeposit/createInvestmentDepositReducer";
import staticsReducer from "./statics/staticsReducer";
import exchangeReducer from "./exchange/exchangeReducer";
import createDepositReducer from "./createDeposit/createDepositReducer";
import getMyInvestmentReducer from "./getMyInvestment/getMyInvestmentReducer";
import createPaymentIntentReducer from "./createPaymentIntent/createPaymentIntentReducer";
import quotaRatesReducer from "./quotaRates/quotaRatesReducer";
import updateUserReducer from "./updateUser/updateUserReducer";
import updatePasswordReducer from "./updatePassword/updatePasswordReducer";
import createPaymentIntentForFintocReducer from "./createPaymentIntentForFintoc/createPaymentIntentForFintocReducer";
const rootReducer = combineReducers({
  getThemeReducer,
  getLanguageReducer,
  signUpReducer,
  signInReducer,
  userInfoReducer,
  getCurrentAprRatesReducer,
  createWithdrawalReducer,
  getMyTransactionReducer,
  createInvestmentDepositReducer,
  staticsReducer,
  exchangeReducer,
  createDepositReducer,
  getMyInvestmentReducer,
  createPaymentIntentReducer,
  quotaRatesReducer,
  updateUserReducer,
  updatePasswordReducer,
  createPaymentIntentForFintocReducer,
});
export default rootReducer;
